import { getCommonParamsItemLabel } from '@/utils/common.js'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'title',
      label: 'profile.title',
      width: 180
    },
    {
      prop: 'articleType',
      label: 'profile.article-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'article_type')
      }
    },
    {
      prop: 'langType',
      label: 'profile.lang-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'lang_type')
      },
      width: 130
    },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'article_status')
      },
      width: 140
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    },
    {
      label: 'common.app-operation',
      width: 380,
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
